import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import {Link} from 'react-router-dom';
import './NotFound.css';

const NotFound = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='404 • Saks & Li'>
      <main id='notfound'>
        <div>
          <i>help</i>
          <p>404 • The page you requested could not be found.</p>
          <p>Return <Link to='/'>home</Link>.</p>
        </div>
      </main>
    </Title>
  </Analytics>;

NotFound.propTypes = {
  location: object.isRequired
};

export default NotFound;