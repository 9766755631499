import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import './Disclaimer.css';

const para1 = 'This site provides general information to those interested in learning about our firm. It is not intended to provide legal advice. Visitors should not act based on any information on this site. The firm expressly disclaims all liability for actions taken or not taken based on any contents of this site.';
const para2 = 'Visitors should not consider information available on this site to be an invitation for an attorney-client relationship, should not rely on the information provided herein as legal advice for any purpose, and should always seek the legal advice of competent counsel in the relevant jurisdiction.';
const para3 = 'This firm maintains offices in New York and neither intends nor purports to practice in any other jurisdiction. This site contains attorney advertising: prior results do not guarantee a similar outcome.';
 
const Disclaimer = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Disclaimer • Saks & Li'>
      <main id='disclaimer'>
        <section className='text'>
          <h3>Disclaimer</h3>
          <p>{para1}</p>
          <p>{para2}</p>
          <p>{para3}</p>
        </section>
      </main>
    </Title>
  </Analytics>;

Disclaimer.propTypes = {
  location: object.isRequired
};


export default Disclaimer;