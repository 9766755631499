import {PureComponent} from 'react';
import {arrayOf, element, oneOfType} from 'prop-types';
import {withRouter} from 'react-router'; 
import setDisplayName from 'recompose/setDisplayName.js';

class ScrollTop extends PureComponent {
  componentDidUpdate(prevProps){
    if (this.props.location !== prevProps.location){
      window.scrollTo(0, 0);
    }
  }
  render(){
    return this.props.children;
  }
}

ScrollTop.propTypes = {
  children: oneOfType([element, arrayOf(element)])
}

export default setDisplayName('Scroll.Top')(
  withRouter(ScrollTop)
);