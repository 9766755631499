import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import list from './list.js';
import './Practices.css';

const dasherize = s =>
  s.replace(' ', '-').toLocaleLowerCase();

const Practice = ({name, children}) =>
  <li id={dasherize(name)}>
    <h5>{name}</h5>
    {React.Children.only(children)}
  </li>;

const QuickList = () =>
  <ul id='quicklist'>{list.map(
    ({name}, i) =>
      <li key={`practice-${i}`}>
        <a href={`#${dasherize(name)}`}>{name}</a>
      </li>)}
  </ul>;

const MainList = () =>
  <ul id='mainlist'>{list.map(
    ({name, content}, i) =>
      <Practice key={`practice-${i}`} name={name}>
        {content()}
      </Practice>)}
  </ul>;

const Practices = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Practice Areas • Saks & Li'>
      <main id='practices' itemScope='mainEntityOfPage'>
        <h3>Practices</h3>
        <div>
          <QuickList/>
          <MainList/>
        </div>
      </main>
    </Title>
  </Analytics>;

Practices.propTypes = {
  location: object.isRequired
};

export default Practices;