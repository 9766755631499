import React from 'react';
import {array, object, string} from 'prop-types';

const Practices = ({practices}) =>
  <div className='practices'>
    <h2>Practice Areas</h2>
    {practices.map((p, i) =>
      <p key={i}>{p}</p>)}
  </div>;

const Education = ({education}) =>
  <div className='education' itemScope='alumniOf'>
    <h2>Education</h2>
    {education.map((e, i) =>
      <div key={i}>
        <div className='school'>
          <span>{e.degree}, {e.school}</span>
        </div>
        {e.major &&
          <div className='major'>{e.major}</div>}
        {/*e.awards &&
          <div className='awards'>
            {e.awards.map((a, i) =>
              <span key={i}>
                {i > 0 && ', '}{a}
              </span>)}
          </div>*/}
      </div>)}
  </div>;

const Admissions = ({admissions}) =>
  <div className='admissions' itemScope='awards'>
    <h2>Bar & Court Admissions</h2>
    {admissions.map((a, i) =>
      <p key={i}>{a}</p>)}
  </div>;

const Languages = ({languages}) =>
  <div className='languages'>
    <h2>Languages</h2>
    {languages.map((l, i) =>
      <p key={i}>{l}</p>)}
  </div>;

const Credentials = ({
  education,
  practices,
  admissions,
  languages
}) =>
  <div className='credentials' itemScope='description'>
    <div className='info'>
      <Practices practices={practices}/>
      <Education education={education}/>
      <Admissions admissions={admissions}/>
      {languages && <Languages languages={languages}/>}
    </div>
  </div>;

Credentials.propTypes = {
  admissions: array.isRequired,
  bio: array.isRequired,
  contact: object.isRequired,
  education: array.isRequired,
  image: string.isRequired,
  name: object.isRequired
};

export default Credentials;