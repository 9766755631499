import React from 'react';
import {array, object, string} from 'prop-types';

const Lead = ({contact, image, name}) =>
  <div className='lead'>
    <div className='inner'>
      <div className='contact'>
        <h1 itemProp='name'>
          <span itemScope='givenName'>
            {name.first}</span>&nbsp;
          <span itemScope='familyName'>
            {name.last}
          </span>
        </h1>
        <div className='details'>
          <p itemScope='telephone'
            className='telephone'>
            <i>phone</i>
            {contact.phone}
          </p>        
          <p itemScope='email'
            className='email'>
            <i>email</i>
            {contact.email}
          </p>
        </div>
      </div>
      <div className='image'>
        <img
          src={image}
          alt={`${name.first} ${name.last}`}
          itemProp='photo'/>
      </div>
    </div>
  </div>;

Lead.propTypes = {
  admissions: array.isRequired,
  bio: array.isRequired,
  contact: object.isRequired,
  education: array.isRequired,
  image: string.isRequired,
  name: object.isRequired
};

export default Lead;