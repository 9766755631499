import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import Attorney from './Attorney.js';
import jrs from './jrs.js';
import yyl from './yyl.js';
import './Attorneys.css';

const Attorneys = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Attorneys • Saks & Li'>
      <main id='attorneys' itemScope='members'>
        <Attorney {...jrs}/>
        <Attorney {...yyl}/>
      </main>
    </Title>
  </Analytics>;

Attorneys.propTypes = {
  location: object.isRequired
};

export default Attorneys;