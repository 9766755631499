import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import Logo from '../Logo/Logo.js';
import './Header.css';

const Navs = [
  {route: '/attorneys', name: 'Attorneys'},  
  {route: '/practices', name: 'Practice Areas'},
  {route: '/contact', name: 'Contact Us'}
];

const NavItem = ({route, name}) =>
  <li>
    <NavLink exact
      to={route}
      activeClassName='active'>
      {name}
    </NavLink>
  </li>;

const Header = () =>
  <header id='header'>
    <h1>
      <Link to='/' title='Home'>
        <Logo/>
      </Link>
    </h1>
    <nav>
      <ul>{Navs.map((n, i) =>
        <NavItem key={i} {...n}/>)}
        {/*<li>
          <a href='https://blog.saksli.com'>Articles</a>
        </li>*/}
      </ul>
    </nav>
  </header>;

export default Header;