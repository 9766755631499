import React from 'react';
import {NavLink} from 'react-router-dom';
import './Footer.css';

const year = new Date().getFullYear();

const Footer = () =>
  <footer id='footer'>
    <p>© {year} Saks & Li LLP</p>
    <nav>
      <ul>
        <li>
          <NavLink exact
            to='notice'
            className='underlined'
            activeClassName='active'>
            Attorney Advertising
          </NavLink>
        </li>
        <li>
          <NavLink exact
            to='disclaimer'
            className='underlined'
            activeClassName='active'>
            Disclaimer
          </NavLink>
        </li>
        <li>
          <NavLink exact
            to='privacy'
            className='underlined'
            activeClassName='active'>
            Privacy Policy
          </NavLink>
        </li>
      </ul>
    </nav>
  </footer>;

export default Footer;