import React from 'react';
import {array, object, string} from 'prop-types';

const Bio = ({bio, matters}) =>
  <div className='bio' itemScope='description'>
    {bio.map((el, i) =>
      <p key={i}>{el}</p>)}
    {!!matters && <h1 id='matters'>Representative Matters</h1>}
    {!!matters && matters.map((el, i) =>
      <p key={i} className='matter'>{el}</p>)}
  </div>;

Bio.propTypes = {
  admissions: array.isRequired,
  bio: array.isRequired,
  contact: object.isRequired,
  education: array.isRequired,
  image: string.isRequired,
  name: object.isRequired
};

export default Bio;