import {PureComponent} from 'react';
import {arrayOf, element, oneOfType, string} from 'prop-types';

const {REACT_APP_GTAG_ID: id} = process.env;
const {gtag} = window;

class Analytics extends PureComponent {
  componentDidMount(){
    if (!gtag) return;
    const page_path = this.props.pathname;
    gtag('config', id, {page_path})
  }
  render(){
    return this.props.children;
  }
}

Analytics.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  pathname: string.isRequired
};

export default Analytics;