import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Header from 'react/Header/Header.js';
import Footer from 'react/Footer/Footer.js';
import Attorneys from 'react/Attorneys/Attorneys.js';
import Contact from 'react/Contact/Contact.js';
import Disclaimer from 'react/Disclaimer/Disclaimer.js';
import Notice from 'react/Notice/Notice.js';
import Practices from 'react/Practices/Practices.js';
import Privacy from 'react/Privacy/Privacy.js';
import Home from 'react/Home/Home.js';
import NotFound from 'react/NotFound/NotFound.js';
import ScrollTop from 'react/ScrollTop/ScrollTop.js';
import './App.css';

const App = <BrowserRouter>
  <ScrollTop>
    <div id='app'>
      <Header/>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/attorneys' component={Attorneys}/>
        <Route exact path='/contact' component={Contact}/>
        <Route exact path='/disclaimer' component={Disclaimer}/>
        <Route exact path='/notice' component={Notice}/>
        <Route exact path='/practices' component={Practices}/>
        <Route exact path='/privacy' component={Privacy}/>
        <Route component={NotFound}/>
      </Switch>
      <Footer/>
    </div>
  </ScrollTop>
</BrowserRouter>;

export default App;