import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import {version} from '../../../package.json';
import './Privacy.css';

const list = items =>
  items.map((item, index) =>
    <li key={index}>{item}</li>);

const para1 = 'This privacy policy applies to information collected online from users of this site. In this policy, you can learn what kind of information we collect, when and how we might use that information, how we protect the information, and the choices you have with respect to your personal information.';
const para2 = 'We don’t share any of the information you provide to us with others, unless we say so in this Privacy Policy, or when we believe in good faith that the law requires it.';
const para3 = 'Please do not submit any confidential, proprietary or sensitive personally identifiable information (e.g. Social Security Number; date of birth; drivers license number; or credit card, bank account or other financial information) (collectively, “Sensitive Information”). If you submit any Sensitive Information, you do so at your own risk and we will not be liable to you or responsible for consequences of your submission.';
const para4 = 'We may also use information you provide to us to communicate with you in the future. If you do not wish to receive such communications, you may notify us that you wish to opt out (unsubscribe).';
const para5 = 'When you visit our website, we may track information about your visit and store that information in web server logs, which are records of the activities on our sites. The servers automatically capture and save the information electronically. Examples of the information we may collect include:';
const list1 = [
  'your unique internet protocol address;',
  'the name of your unique internet service provider;',
  'the town/city, county/state and country from which you access our website;',
  'the kind of browser or computer you use;',
  'the number of links you click within the site;',
  'the date and time of your visit;',
  'the web page from which you arrived to our site;',
  'the pages you viewed on the site; and',
  'certain searches/queries that you conducted via our site.'
];
const para6 = 'The information we collect via site analytics helps us administer the site, analyze its usage, protect the website and its content from inappropriate use and improve the user’s experience.';
const para7 = 'We take certain appropriate security measures to help protect your personal information from accidental loss and from unauthorized access, use or disclosure. However, we cannot guarantee that unauthorized persons will always be unable to defeat our security measures.';
const para8 = 'We do not sell, rent, or lease mailing lists or other user data to others, and we do not make your personal information available to any unaffiliated parties, except as follows:';
const list2 = [
  'to agents, website vendors and/or contractors who may use it on our behalf or in connection with their relationship with us;',
  'if we are unable to assist with your matter, but know an unaffiliated attorney or firm that may be able to help you, we may refer you and share information you provided us with that party; and',
  'as required by law, in a matter of public safety or policy, as needed in connection with the transfer of our business assets (for example, if we are acquired by another firm or if we are liquidated during bankruptcy proceedings), or if we believe in good faith that sharing the data is necessary to protect our rights or property.'
];
const para9 = 'You may opt out of any future contacts from us at any time. Contact us via the mailing address on our website at any time to:';
const list3 = [
  'see what data we have about you, if any;',
  'change/correct any data we have about you;',
  'ask us to delete any data we have about you; and/or',
  'opt out of future communications from us.'
];
const para10 = 'If you have any additional questions or concerns about this privacy policy, please contact us via the phone number, contact form or mailing address listed on this website. If our information practices change in a significant way, we will post the policy changes here.';

const Privacy = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Privacy Policy • Saks & Li'>
      <main id='privacy'>
        <section className='text'>
          <h3>Privacy Policy</h3>
          <p>{para1}</p>
          <p>{para2}</p>
          <p>{para3}</p>
          <p>{para4}</p>
          <p>{para5}</p>
          <ul>{list(list1)}</ul>
          <p>{para6}</p>
          <p>{para7}</p>
          <p>{para8}</p>
          <ul>{list(list2)}</ul>
          <p>{para9}</p>
          <ul>{list(list3)}</ul>
          <p>{para10}</p>
        </section>
        <section id='version'>
          Site v{version}
        </section>
      </main>
    </Title>
  </Analytics>;

Privacy.propTypes = {
  location: object.isRequired
};

export default Privacy;