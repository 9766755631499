import React from 'react';
import {Link} from 'react-router-dom';

const Welcome = () =>
  <section id='welcome'>
    <i id='settings'>settings</i>
    <h3 itemProp='description'>
      Saks & Li is a boutique law firm counseling growth companies, entrepreneurs and executives through complex business decisions.
    </h3>
    <Link to='contact'
      title='Contact Us'
      itemProp='contactPoint'
      id='contact'>
      Contact Us
    </Link>
  </section>;

export default Welcome;