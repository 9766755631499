import React from 'react';
import {array, object, string} from 'prop-types';
import Lead from './Lead.js';
import Bio from './Bio.js';
import Credentials from './Credentials.js';

const Attorney = props =>
  <section
    className='attorney'
    itemProp='member'
    itemScope
    itemType='http://schema.org/Person'>
    <Lead {...props}/>
    <section>
      <Bio {...props}/>
      <Credentials {...props}/>      
    </section>
  </section>;

Attorney.propTypes = {
  admissions: array.isRequired,
  bio: array.isRequired,
  contact: object.isRequired,
  education: array.isRequired,
  image: string.isRequired,
  name: object.isRequired
};

export default Attorney;