import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import './Notice.css';

const para1 = 'This site contains attorney advertising. Prior results do not guarantee a similar outcome.';

const Notice = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Notice • Saks & Li'>
      <main id='notice'>
        <section className='text'>
          <h3>Attorney Advertising</h3>
          <p>{para1}</p>
        </section>
      </main>
    </Title>
  </Analytics>;

Notice.propTypes = {
  location: object.isRequired
};

export default Notice;