import React from 'react';
import {object} from 'prop-types';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import {Link} from 'react-router-dom';
import Gmap from './Gmap.js';
import './Contact.css';

const Contact = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Contact Us • Saks & Li'>
      <main id='contact'>
        <section className='text'>
          <h3>Contact Us</h3>
          <div id='contact' itemProp='contactPoint'>
            <h5 itemProp='legalName'>Saks & Li LLP</h5>
            <div itemProp='address'>30 Broad Street, Suite 14133</div>
            <div itemProp='address'>New York, New York 10004</div>
            <div itemProp='telephone'>(347) 916-4424</div>
            <div itemProp='email'>contact@saksli.com</div>
          </div>
          <section id='map'>
            <Gmap/>
          </section>      
          <p className='contact'>
            Saks & Li is located in downtown Manhattan. To contact one of our professionals by phone or email, please visit our&nbsp;
            <Link to='attorneys' className='underlined'>attorneys</Link> page.
          </p>
        </section>
      </main>
    </Title>
  </Analytics>;

Contact.propTypes = {
  location: object.isRequired
};

export default Contact;