import React from 'react';
import Analytics from 'react/Analytics/Analytics.js';
import Title from 'react-document-title';
import Welcome from './Welcome.js';
import Practices from './Practices.js';
import './Home.css';

const Home = ({location: {pathname}}) =>
  <Analytics pathname={pathname}>
    <Title title='Saks & Li'>
      <main id='home' itemScope='mainEntityOfPage'>
        <Welcome/>
        <Practices/>
      </main>
    </Title>
  </Analytics>;

export default Home;