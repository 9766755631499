import image from './Images/JeremySaks.jpg';

export default {
  admissions: [
    'New York',
    'New Jersey',
    'District of Columbia (Inactive)',
    'U.S. District Court, S.D.N.Y.',
    'U.S. District Court, E.D.N.Y.',
    'U.S. District Court, District of New Jersey',
    '2nd Circuit Court of Appeals'
  ],
  bio: [
    'Jeremy is a seasoned corporate and commercial attorney. His focus is contentious business matters.',
    'Jeremy\'s corporate and commercial practice centers on business litigation and litigation avoidance, complex dispute resolution and advisory work on corporate governance, risk management and best practices. His clients include startups, closely held companies and growth businesses, as well as investors and skilled professionals in technology and computing, trading, finance, health care, consumer products and insurance. He strives to identify simple solutions to complex problems, and provide practical, real-world advice in fast-paced industries.',
    'Jeremy counsels stakeholders in fundamental conflicts over board control, divestiture, reorganization, acquisition and breakup, as well as so-called “business divorces” and "bet the company" disputes. He defends attorneys and other licensed professionals accused of misconduct in highly regulated industries. An experienced white collar defense practitioner, Jeremy has successfully represented companies and individuals in state and federal courts in cases involving allegations of fraud, breach of fiduciary duty and other malfeasance, and led internal investigations on behalf of companies of every scale.',
    'Before founding Saks & Li, Jeremy practiced for nearly a decade in the White Collar Defense Group of Kramer Levin Naftalis & Frankel LLP in New York, where he handled high profile securities litigation and corporate investigations in consultation with the DOJ, SEC and state attorneys general. Jeremy is outside counsel to RPCK Rastegar Panchal LLP in New York, where he advises the firm and its clients on business disputes and ethics matters. He is a graduate of NYU School of Law and Wesleyan University.'
  ],
  matters: [
    'Achieved complete dismissal of a New York shareholder derivative lawsuit alleging breaches of fiduciary duty and fraud against individual and corporate defendants in the aftermath of a public accounting scandal;',
    'Successfully resolved the contentious breakup of an industrial multimedia company and follow-on federal court litigation via a confidential settlement and release of claims;',
    'Effectively represented a Delaware investment company in a multimillion-dollar dispute with a dissident shareholder over board composition and stock redemption;',
    'Obtained a favorable pre-trial settlement and dismissal for individual officers and corporate defendants in a New York shareholder derivative suit alleging looting, corporate malfeasance and waste in connection with founder compensation;',
    'Obtained a confidential seven-figure settlement on behalf of a plaintiff investor alleging breach of contract and fraud in a film industry financing deal;',
    'Obtained a desirable confidential pre-trial settlement on behalf of the plaintiff in a breach of contract action against a prominent national news publication;',
    'Effectively defended a Delaware corporation engaged in public merger activity from shareholder suit and related books and records action;',
    'Helped achieve complete dismissal of direct and derivative shareholder claims against the chairman of the board of a public medical research company in connection with high profile claims of insider trading and fraud;',
    'Successfully represented a C-suite executive of a smartphone component manufacturer under investigation for leaking confidential device specs ahead of product launch.'
  ],
  contact: {
    phone: '(347) 916-4454',
    email: 'jeremy@saksli.com'
  },
  education: [
    {
      school: 'New York University School of Law',
      degree: 'J.D.',
      awards: null
    },
    {
      school: 'Wesleyan University',
      degree: 'B.A.',
      major: 'Philosophy',
      awards: ['High Honors', 'Phi Beta Kappa']
    }
  ],
  image,
  name: {
    first: 'Jeremy',
    last: 'Saks'
  },
  practices: [
    'Corporate & Commercial Disputes',
    'Business Litigation',
    'Commercial Agreements',
    'White Collar Defense'
  ]
};