import React from 'react';
import {Link} from 'react-router-dom';

const items = [
  {
    heading: 'business disputes',
    body: 'We solve complex corporate and commercial law disputes among company stakeholders, founders and executives. We tackle high-stakes contests for board control, shareholder derivative suits, business and managerial crises, "bet the company" strategic events and so-called "business divorces." We litigate where necessary and share strategic guidance at every turn.'
  },
  {
    heading: 'commercial agreements',
    body: 'We negotiate and structure a range of complex commercial agreements including master service agreements, ip licenses, software-as-a-service and cloud computing engagements, as well as agreements governing joint ventures, data use and protection, and executive and consulting services.'
  }
];

const Practices = () =>
  <section id='practices'>
    <div className='areas'>
      {items.map((item, i) =>
        <div key={i}>
          <h5>{item.heading}</h5>
          <p>{item.body}</p>
        </div>)}
    </div>
    <div className='more'>
      <Link to='practices' title='Practice Areas'>
        See more about our practice areas &nbsp; >>
      </Link>
    </div>
  </section>;

export default Practices;