import React from 'react';

export default [
  {
    name: 'Business Disputes',
    content: () =>
      <div>
        <p>Saks & Li represents individuals and companies in complex business disputes across a wide range of industries. We work to deeply understand the motivations of every key stakeholder and identify levers to unlock efficient solutions to difficult problems. When called upon, we will litigate aggressively to defend our clients' interests.</p>
        <p>Our experiences include successfully resolving venture-backed board disputes, successfully defending shareholder derivative actions brought against companies and key executives, overcoming deadlocks among founders, and developing strategic plans for executives during the reorganization of distressed companies. We are frequently engaged to handle disputes over ownership and control of businesses, so-called "business divorces," and contests over executive talent and intellectual property.</p>
        <p>We litigate direct and derivative actions in state and federal courts, often achieving dismissal at the earliest stage.</p>
      </div>
  },
  {
    name: 'Commercial Agreements',
    content: () =>
      <div>
        <p>Saks & Li negotiates a broad range of commercial agreements with key vendors, suppliers, partners, executives and other service providers. We routinely handle master services agreements, software-as-a-service agreements, intellectual property licenses, terms of service, clickwrap and clickthrough agreements, and data and cloud provider contracts in a range of industries, including private equity, tech, manufacturing and consumer products. Counterparties include many of the world’s biggest companies, such as Motorola, T-Mobile, Ford Motor Company, Shell Oil, Citadel, SAP Hybris and Citibank, as well as hundreds of small and medium-sized software and service providers.</p>
        <p>We draft, negotiate and counsel on agreements of every size and commercial relationship, from short term commitments to multi-year agreements spanning jurisdictions and continents. As with all engagements, our goals are clarity, efficiency and protection of our client’s business interests.</p>
      </div>
  },
  {
    name: 'General Counsel Services',
    content: () =>
      <div>
        <p>We provide general counsel guidance and support services for entrepreneurs, private equity firms, new ventures and growth companies, including regulatory and compliance risk management, litigation risk counseling, and development of company policies, practices and foundational documents. Our strategic guidance includes offensive and defensive planning for safeguarding assets and intellectual property.</p>
        <p>We frequently act as standing counsel to companies seeking the knowledge and experience of an inside general counsel without the commitment to a full time position. We work with such companies under a variety of fee arrangements to ensure their business needs are met through the different stages of their growth.</p>
      </div>
  },
  {
    name: 'White Collar Defense',
    content: () =>
      <div>
        <p>We have extensive experience defending companies and executives in criminal and disciplinary investigations and prosecutions by the Securities and Exchange Commission, the Department of Justice, FINRA and other agencies. We have successfully defended executives in actions involving allegations of stock fraud, insider trading, market manipulation, mail and wire fraud, embezzlement, bribery and FCPA violations, and other improprieties.</p>
        <p>As veterans of some of New York's largest law firms, we have extensive experience conducting corporate internal investigations for businesses of every scale, from sole proprietorships to Fortune 100 companies. We conduct macro and micro-sized investigations on behalf of management and audit committees, boards and court-appointed monitors, as well as defend executives involved in such investigations. We have deep experience handling independent reviews concerning fraud, mismanagement, insider trading, stock manipulation and other trading improprieties, workplace harrasment and discrmination, and violations of company policies and guidelines.</p>
      </div>
  },
  {
    name: 'Employment Matters',
    content: () =>
      <div>
        <p>We have helped dozens of executives in diverse industries including health care, hedge funds, trading desks, banks, insurers and high tech navigate the complexities of their employment agreements, severance and restrictive covenant arrangements. We also help employers structure equitable and fair employment agreements and company policies for the personnel decisions that shape their businesses.</p>
      </div>
  }
];