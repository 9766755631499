import image from './Images/YingYingLi.jpg';

export default {
  admissions: [
    'New York',
    'U.S. District Court, S.D.N.Y.',
    'U.S. District Court, E.D.N.Y.'
  ],
  bio: [
    'Ying is a litigator with a diverse practice representing corporations and individuals in commercial, regulatory, criminal, employment and intellectual property matters. Ying represents clients in federal and state courts, before government agencies, and in arbitrations and mediations. She litigates cases involving a broad range of issues and claims, including breach of contract, securities fraud, false advertising, breach of fiduciary duty, whistleblower claims, and enforcement of employment, non-competition and severance agreements.',
    'Ying\'s work on behalf of clients includes obtaining temporary and permanent injunctive relief for multiple consumer products companies; overseeing complex investigations related to money laundering, fraud, trade secret theft, and brand protection, working closely with state and federal law enforcement and prosecutors on behalf of brand owners who had been victims of intellectual property crimes; conducting an internal investigation of the Chinese offices of a major international financial services company in the context of the U.S. Foreign Corrupt Practices Act; and notable securities matters, including representing a major financial institution in civil proceedings arising from the collapse of two investment funds during the subprime mortgage crisis. She has also successfully represented several pro bono individuals in their applications for political asylum.',
    'Before founding Saks & Li, Ying was a litigator at Kramer Levin Naftalis & Frankel LLP and Skadden Arps, in New York. She is a graduate of Yale Law School, and is fluent in conversational Mandarin Chinese.'
  ],
  contact: {
    phone: '(347) 916-4516',
    email: 'ying@saksli.com'
  },
  education: [
    {
      school: 'Yale Law School',
      degree: 'J.D.',
      awards: null
    },
    {
      school: 'McMaster University',
      degree: 'B. Arts & Sc.',
      major: null,
      awards: ["Dean's List"]
    }
  ],
  image,
  languages: [
    'English',
    'Mandarin'
  ],
  name: {
    first: 'Ying Ying',
    last: 'Li'
  },
  practices: [
    'Business Disputes & Litigation',
    'White Collar Defense',
    'Employment Law',
    'General Counsel Services'
  ]
};